.nav-link {
    text-decoration: none;
    color:#333;
    transition: color 0.3 ease;
}

.nav-link:hover {
    color: #007BFF; /* Adjust the hover color to your preference */
  }

.h-wrapper {
    background: var(--gray);
    color: white;
    z-index: 99;
}

.h-container {
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--black);
    z-index: 2;
}

.h-menu {
    gap: 2rem;   
}

.h-menu>*:hover {
    cursor: pointer;
}

.menu-icon {
    display: none;
}

@media (max-width: 768px) {
    .menu-icon{
        display: block;
    }
    .h-menu {
        z-index: 99;
        color: var(--black);
        position: absolute;
        top: 3rem;
        right: 4rem;
        background: white;
        flex-direction: column;
        font-weight: 50;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        transition: all 300ms ease-in;
    }
}

/* Add these styles for the dropdown */
.dropdown {
    position: relative;
    display: inline-block;
  }
  
.dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: #333; /* Adjust color as needed */
    background-color: inherit;
    cursor: pointer;
    padding: 14px 16px;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9; /* Adjust background color as needed */
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
  
.dropdown-content a {
    color: #333; /* Adjust color as needed */
    padding: 12px 16px;
    display: block;
    text-decoration: none;
    min-width: 200px;
}
  
.dropdown-content a:hover {
    background-color: #ddd; /* Adjust background color on hover as needed */
}
  
.dropdown:hover .dropdown-content {
    display: block;
    animation: fadeIn 0.3s ease-in-out;
}
  
@keyframes fadeIn {
    from {
        opacity: 0
    }
    to {
        opacity: 1;
    }
}