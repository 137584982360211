.hero-wrapper {
    color: black;
    position: relative;
    padding-bottom: 2rem;
    background-color: var(--gray)
}

.hero-container {
    justify-content: space-around;
    align-items: flex-end;
}

/* left side */
.hero-left {
    gap: 3rem;
    margin-bottom: 60px;
}

.hero-title {
    position: relative;
}

.hero-title>h1 {
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}

.stats {
    width: 100%;
    justify-content: space-between;
}

.stat>:nth-child(1) {
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child {
    color:orange;
}

/* right side */

.image-container{
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255, 255, 255, 0.12);
}

.image-container>img {
    width: 100%;
    height: 100%;
}

@media (max-width: 640px) {
    .hero-containerr {
        margin-top: 2rem;
    }
    .hero-title>h1 {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .image-containe {
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap: 1.5rem;
    }
    .hero-right {
        width: 100%;
    }
    .stat>:nth-child(1) {
        font-size: 1.5rem;
    }

    .stat>:nth-child(2){
        font-size: 0.8rem;
    }
}