.au-left {
    gap: 0.5rem;
}

.au-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 1rem;
}

.au-container>div {
    flex:1;
}

.au-container .image-container {
    border: 8px solid rgb(232 232 232 / 93%);
}

.centerText {
    align-items: center;
}