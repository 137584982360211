.g-wrapper {
    padding: 40px
}

.g-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the items horizontally */
    text-align: center; /* Center the text in the container */
}

.g-image-container {
    display: flex;
    flex-direction: row;
}

.g-image-padding {
    padding: 0 10px 20px;
}

.g-tittle-padding {
    padding: 0px 0px 20px
}