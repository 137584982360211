.i-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the items horizontally */
    text-align: center; /* Center the text in the container */
}

.inquire-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 700px; /* Adjust the width as needed */
    margin: auto;
}

.inquire-form>button {
  min-width: 300px;
  margin-top: 1rem;
}

.input-field {
  min-width: 600px !important;
  width: 100%;
  padding: 0.6rem;
  border: 1px solid var(--primary);
  border-radius: 10px;
  font-size: 1.4rem;
}

.checkbox-label {
  display: inline-flex;
  padding-left: 20px;
}

.checkbox-field {
  width: auto; /* Adjust the width as needed */
  border: 1px solid var(--primary);
  border-radius: 10px;
  font-size: 1.4rem;
}

.i-h1 {
  font-size: 1.2rem; /* Set the font size to make it smaller */
  text-align: left; /* Align the text to the left */
}

.i-span {
  padding-left: 5px;
}