.sd-left {
    gap: 0.5rem;
}

.sd-container>div {
    flex:1;
}

.sd-container .image-container {
    border: 8px solid rgb(232 232 232 / 93%);
}

.sd-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 1rem;
}