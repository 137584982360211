.s-container {
    overflow: hidden;
    position: relative;
}

.s-head {
    margin-bottom: 2rem;
}

.s-card {
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: auto;
    transition: all 300ms ease-in
}

.s-card>img {
    width: 100%;
    max-width: 15rem;
}

.s-card>:nth-child(2) {
    font-size: 1.5em;
}

.s-card:hover {
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(136, 160, 255, 0.46) 217.91%
    );
    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}

.swiper-horizontal {
    overflow: visible;
}

.s-image-container {
    width: 200px; /* Set the desired width */
    height: 200px; /* Set the desired height */
    overflow: hidden; /* Ensure that the rounded corners are applied */
    border-radius: 10px; /* Set the radius for rounded corners */;
  }
  
.s-image-container img {
    width: 100%; /* Make the image fill the container */
    height: 100%; /* Make the image fill the container */
    object-fit: cover; /* Ensure the image covers the entire container */
    border-radius: 10px; /* In case the image itself doesn't fill the container */
}

.s-button {
    position: absolute;
    gap: 1rem;
    top: -4rem;
    right: 0;
}

.s-button button {
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: blue;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.s-button>:nth-child(1) {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

.s-button>:nth-child(2) {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

@media (max-width: 640px) {
    .s-head {
        align-items: center;
    }
    .s-button {
        position: initial;
    }
    .s-card {
        align-items: center;
    }
}